@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Vazirmatn';
  src: url('./styles/vazir/Vazir.woff2') format('woff2'),
    url('./styles/vazir/Vazir.woff') format('woff'),
    url('./styles/vazir/Vazir.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Vazirmatn', sans-serif;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-layout-footer {
  padding: 0;
}

.anticon svg {
  font-size: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* :where(.css-dev-only-do-not-override-1hpnbz2).ant-carousel .slick-dots li button {
  background: "red";
} */
:where(.css-dev-only-do-not-override-1hpnbz2).ant-carousel .slick-dots li button {
  background: red;
}

/* .slick-active {
  background: green;
  color: greenyellow;
} */

:where(.css-dev-only-do-not-override-1hpnbz2).ant-carousel .slick-dots li button::after {
  background: rgba(89, 167, 94, .2);
  border-radius: 15px;
}

html {
  scroll-behavior: smooth;
}